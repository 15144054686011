import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { RootState } from '~/store/redux-store'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { getProfileIdFromJwt } from '~/lib/utils/getProfileIdFromJwt'

export function getFetchConfigParamsData(api: BaseQueryApi) {
  const { getState } = api
  const state = getState() as unknown as RootState
  const whitelabel = whiteLabelSelector(state)

  const profile_mnemocode = state.authentication.profile?.profile_mnemocode
  const uuid = state.authentication.profile?.uuid
  const { prc_mode, company, bankMnemocode } = whitelabel
  const { sessionToken } = state.authentication
  const profileId = getProfileIdFromJwt(sessionToken)

  return {
    profile_mnemocode,
    prc_mode,
    company,
    uuid,
    bankMnemocode,
    sessionToken,
    profileId,
  }
}
