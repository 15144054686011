import type { DetailBonusDirection } from '~/models/bonusDetails'
import type { BalanceDetailsData, BalancesTotalAll, LastCountedPeriod, Transaction } from '~/models/balance'
import type { RootState } from '~/store/redux-store'
import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { format } from 'date-fns'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { activeEntriesSelector, productGroupsSelector } from '~/store/api/entries'
import { programsQuerySelector } from '~/store/api/programs'
import { createSelector } from 'reselect'
import { getFetchConfigParamsData } from '~/store/api/utils/getFetchConfigParamsData'
import { productProgramsAllProgramsSelector } from '~/store/api/options'
import { getOftenPeriod, periodsCanBeOnline } from '~/lib/utils/getOftenPeriod'
import { isPeriodBefore } from '~/lib/utils/comparePeriods'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { MovementDetail } from '~/models/balance'
import { gatewayApiQuery, getGatewayApiUrlFromQueryApi, shouldUseGatewayApi } from '~/lib/gatewayApi'
import { sleep } from '~/lib/utils/sleep'
import { getFetchingParams } from './utils/getFetchingParams'

const axiosRTQuery = createAxiosRTQuery()

export const balanceApi = createApi({
  reducerPath: 'balance',
  keepUnusedDataFor: 300,
  tagTypes: ['Total'],
  baseQuery: axiosRTQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getPCMovementDetails: build.query<MovementDetail[], { cardMnemocode: string; period: string }>({
      async queryFn({ period, cardMnemocode }, queryApi) {
        const periodCanBeOnline = periodsCanBeOnline.includes(period)
        let balanceInfoOnlineRes: { data?: number; error?: unknown } | undefined
        const { getState } = queryApi
        const state = getState() as RootState
        const { showPrecalc } = whiteLabelSelector(state)

        const balanceDetailsRequest = queryApi.dispatch(
          balanceApi.endpoints.getBalanceDetails.initiate({
            period,
            card_mnemocode: cardMnemocode,
          })
        )

        if (showPrecalc && periodCanBeOnline) {
          const balanceDetailsOnlineRes = await queryApi.dispatch(
            balanceApi.endpoints.getBalanceDetailsOnline.initiate({
              period,
              card_mnemocode: cardMnemocode,
            })
          )
          const { data: balanceDetailsOnline } = balanceDetailsOnlineRes

          if (balanceDetailsOnlineRes.error) {
            const error = balanceDetailsOnlineRes.error as string

            return { error }
          }

          if (balanceDetailsOnline?.length && balanceDetailsOnline[0]?.status === 'precalc') {
            const { currency } = balanceDetailsOnline[0]

            balanceInfoOnlineRes = await queryApi.dispatch(
              balanceApi.endpoints.getBalanceInfoOnline.initiate({
                period,
                card_mnemocode: cardMnemocode,
                currency,
              })
            )
          }
        }

        const balanceDetailsRes = await balanceDetailsRequest
        const { data: balanceDetails } = balanceDetailsRes

        if (balanceDetailsRes.error) {
          const error = balanceDetailsRes.error as string

          return { error }
        }

        const movementDetails: MovementDetail[] = []
        if (balanceDetails) {
          movementDetails.push({ amount: balanceDetails.accrual, movementType: 'posted', shown: true })
        }
        if (balanceInfoOnlineRes?.data !== undefined) {
          movementDetails.push({ amount: balanceInfoOnlineRes.data, movementType: 'precalc', shown: true })
        }
        if (balanceDetails) {
          movementDetails.push({ amount: balanceDetails.holdSum, movementType: 'hold', shown: true })
          movementDetails.push({ amount: balanceDetails.errorSum, movementType: 'error', shown: true })
        }

        return { data: movementDetails }
      },
    }),
    getBonusDetails: build.query<DetailBonusDirection[], { currency: string; period: string }>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { currency, period } = args

        const params = getFetchingParams({
          period,
          currency,
          paramIsProfileCode: true,
          prc: 'prc_mode',
          cache: false,
          api: queryApi,
        })

        const { company, prc_mode, profile_mnemocode } = getFetchConfigParamsData(queryApi)

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/microservice/metadata/bonus/v3/details`,
              method: 'GET',
              params,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/bonus/details`,
              method: 'GET',
              params: {
                period,
                currency,
                prcMode: prc_mode,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const data = res.data as unknown as DetailBonusDirection[]

        return { data }
      },
    }),
    getBalanceDetails: build.query<BalanceDetailsData, { card_mnemocode: string; period: string; currency?: string }>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { currency, period, card_mnemocode } = args
        const { getState } = queryApi
        const state = getState() as RootState
        const productGroups = productGroupsSelector(state)
        const entry_group_key = productGroups[card_mnemocode]
        const { company, prc_mode, profile_mnemocode } = getFetchConfigParamsData(queryApi)

        const requestUrl = entry_group_key
          ? '00000001/v2/microservice/metadata/balance/v2/details/entry-group'
          : '00000001/v2/microservice/metadata/balance/v2/details'
        const gatewayApiRequestUrl = entry_group_key
          ? `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/balance/details/entry-group`
          : `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/balance/details`

        const params = getFetchingParams({
          period,
          currency,
          card_mnemocode,
          entry_group_key,
          paramIsProfileCode: true,
          prc: 'prcMode',
          cache: false,
          api: queryApi,
        })

        // todo check akbars
        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: requestUrl,
              method: 'GET',
              params,
            })
          : await gatewayApiQuery({
              url: gatewayApiRequestUrl,
              method: 'GET',
              params: {
                paymentCardMnemocode: card_mnemocode,
                period,
                currency,
                prcMode: prc_mode,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const data = res.data as unknown as BalanceDetailsData

        return { data }
      },
    }),
    getBalanceDetailsOnline: build.query<Transaction[], { card_mnemocode: string; period: string }>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { period, card_mnemocode } = args
        const { company, profile_mnemocode, prc_mode } = getFetchConfigParamsData(queryApi)

        const params = getFetchingParams({
          period,
          card_mnemocode,
          paramIsProfileCode: true,
          prc: 'prc_mode',
          cache: false,
          api: queryApi,
        })

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/microservice/metadata/balance/v2/details/online`,
              method: 'GET',
              params,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/balance/details/online`,
              method: 'GET',
              params: {
                paymentCardMnemocode: card_mnemocode,
                period,
                prcMode: prc_mode,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resFinal = res.data as Omit<Transaction, 'status'>[]

        const data: Transaction[] = resFinal.map((transaction) => ({ ...transaction, status: 'precalc' }))

        return { data }
      },
    }),
    getBalanceInfoOnline: build.query<number, { currency: string; period: string; card_mnemocode: string }>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { currency, period, card_mnemocode } = args
        const { company, profile_mnemocode, prc_mode } = getFetchConfigParamsData(queryApi)

        const params = getFetchingParams({
          period,
          currency,
          card_mnemocode,
          paramIsProfileCode: true,
          prc: 'prc_mode',
          cache: false,
          api: queryApi,
        })

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/microservice/metadata/balance/info/online`,
              method: 'GET',
              params,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/balance/info/online`,
              method: 'GET',
              params: {
                paymentCardMnemocode: card_mnemocode,
                period,
                prcMode: prc_mode,
                currency,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as { history: { total: number }[] }
        const data = resData.history[0]?.total || 0

        return { data }
      },
    }),
    getAllBalancesTotal: build.query<BalancesTotalAll, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const currencies = getActiveCurrencies(queryApi)
        const { company } = getFetchConfigParamsData(queryApi)
        // in seconds
        const retryIntervals = [0, 2, 4, 6, 8]

        const fetchBalanceTotal = async (params: ReturnType<typeof getFetchingParams>, currency: string) => {
          const res = !shouldUseGatewayApi(company)
            ? await fetchWithBaseQuery({
                url: '00000001/v2/microservice/metadata/balance/v2/total',
                method: 'GET',
                params,
              })
            : await gatewayApiQuery({
                url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/balance/total`,
                method: 'GET',
                params: {
                  currency,
                },
              })

          return res
        }

        const results = await Promise.all(
          currencies.map(async (currency) => {
            const params = getFetchingParams({
              currency,
              paramIsProfileCode: true,
              api: queryApi,
            })
            let retryCounter = 0

            try {
              let res: Awaited<ReturnType<typeof fetchBalanceTotal>>
              let resData: { amount: number; totalProfit: number; notExactBalance: boolean }

              do {
                if (retryCounter >= retryIntervals.length) {
                  throw new Error('cannot get exact balance for the specified number of attempts')
                }

                // eslint-disable-next-line no-await-in-loop
                await sleep(retryIntervals[retryCounter++] * 1000)
                // eslint-disable-next-line no-await-in-loop
                res = await fetchBalanceTotal(params, currency)
                resData = res.data as typeof resData
              } while (resData.notExactBalance)

              const total = resData.amount ?? 0
              const totalProfit = resData.totalProfit ?? 0

              return { currency, total, totalProfit }
            } catch (err) {
              return { currency, total: null, totalProfit: null }
            }
          })
        )

        if (!results?.length || results?.some((el) => !el)) {
          return { error: 'Something in API went wrong' }
        }

        const data: BalancesTotalAll = {}
        for (const el of results) {
          data[el.currency] = { total: el.total, totalProfit: el.totalProfit }
        }

        return { data }
      },
      providesTags: ['Total'],
    }),
    getLastCountedPeriod: build.query<string, { currency: string }>({
      async queryFn({ currency }, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { company, profile_mnemocode } = getFetchConfigParamsData(queryApi)
        const params = getFetchingParams({
          currency,
          paramIsProfileCode: true,
          api: queryApi,
        })

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/microservice/metadata/bonus/status`,
              method: 'GET',
              params,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/bonus/status`,
              method: 'GET',
              params: {
                currency,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as { periodStartDate: string; status: 'ok' | 'error' }

        return { data: resData.status === 'ok' ? resData.periodStartDate : '2018-01' }
      },
    }),
    getAllLastCountedPeriods: build.query<LastCountedPeriod, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const currencies = getActiveCurrencies(queryApi)
        const currentMonth = getOftenPeriod('thisMonth')
        const lastMonth = getOftenPeriod('lastMonth')
        const { company, profile_mnemocode } = getFetchConfigParamsData(queryApi)

        const results = await Promise.all(
          currencies.map(async (currency) => {
            const fallback = { currency, date: lastMonth }
            const params = getFetchingParams({
              currency,
              paramIsProfileCode: true,
              api: queryApi,
            })

            try {
              const res = !shouldUseGatewayApi(company)
                ? await fetchWithBaseQuery({
                    // done duplicate
                    url: `00000001/v2/microservice/metadata/bonus/status`,
                    method: 'GET',
                    params,
                  })
                : await gatewayApiQuery({
                    url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/bonus/status`,
                    method: 'GET',
                    params: {
                      currency,
                    },
                  })

              const resData = res.data as { periodStartDate: string; status: 'ok' | 'error' }

              if (!(resData?.status === 'ok')) return fallback

              const date = isPeriodBefore(resData.periodStartDate, currentMonth)
                ? format(new Date(resData.periodStartDate), 'yyyy-MM')
                : lastMonth

              return { currency, date }
            } catch (e) {
              return fallback
            }
          })
        )

        if (!results?.length || results?.some((el) => !el)) {
          return { error: 'Something in API went wrong' }
        }

        const data: LastCountedPeriod = {}
        for (const el of results) {
          data[el.currency] = el.date
        }

        return { data }
      },
    }),
    conversion: build.mutation<
      null,
      { currency: string; amount: string; loyaltyMnemocode: string; cardMnemocode: string }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)
        const extendedArgs = { ...args, profileId: profile_mnemocode }

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/currency/exchange`,
              method: 'POST',
              params: extendedArgs,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/currency/exchange`,
              method: 'POST',
              data: {
                currency: extendedArgs.currency,
                amount: extendedArgs.amount,
                loyaltyProgramMnemocode: extendedArgs.loyaltyMnemocode,
                paymentCardMnemocode: extendedArgs.cardMnemocode,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Exchange impossible. API error' }

        const resData = res.data as { data: { status: 'ok' | 'error'; message: string } }
        const { status, message } = resData.data

        if (status === 'error') return { error: message }

        return { data: null }
      },
      invalidatesTags: ['Total'],
    }),
  }),
})

export const {
  useGetBonusDetailsQuery,
  useGetBalanceInfoOnlineQuery,
  useGetAllBalancesTotalQuery,
  useGetAllLastCountedPeriodsQuery,
  useGetBalanceDetailsQuery,
  useGetBalanceDetailsOnlineQuery,
  useConversionMutation,
  useGetPCMovementDetailsQuery,
  useGetLastCountedPeriodQuery,
} = balanceApi

export const balancesTotalQuerySelector = createSelector(
  balanceApi.endpoints.getAllBalancesTotal.select(),
  (result) => result.data ?? {}
)

export const lastCountedPeriodsQuerySelector = createSelector(
  balanceApi.endpoints.getAllLastCountedPeriods.select(),
  (result) => result.data ?? {}
)

function getActiveCurrencies(api: BaseQueryApi) {
  const { getState } = api
  const state = getState() as unknown as RootState

  const entries = activeEntriesSelector(state)
  const programs = programsQuerySelector(state)
  const productProgramsFromOptions = productProgramsAllProgramsSelector(state)

  const authorizedProgramsMnemocodes = entries
    .filter(({ product_class }) => product_class === 'LP')
    .map((entry) => entry.product)

  return programs
    .filter(
      ({ mnemocode, productClass }) =>
        authorizedProgramsMnemocodes?.includes(mnemocode) && productProgramsFromOptions?.includes(productClass)
    )
    .map(({ currency }) => currency)
}
