import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useGetMyProducts } from '~/hooks/useGetProducts'
import { setDefaultCard } from '~/store/slices/authentication'
import { useSelector } from 'react-redux'
import { useGetEntriesQuery } from '~/store/api/entries'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { useGetProfileQuery } from '~/store/api/profile'
import { useAppDispatch, useTypedSelector } from '~/store/redux-store'
import { useGetOptions } from '~/components/layout/content/wrappers/InitialFetch/useGetOptions'
import { useGetProductsQuery } from '~/store/api/products'

interface Props {
  children: JSX.Element
  fallback: JSX.Element
}

export function Authenticated({ children, fallback }: Props) {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const defaultCardMnemocode = useTypedSelector(({ authentication }) => authentication.profile.defaultCard)

  const { isSuccess: profileIsReady } = useGetProfileQuery()
  const { isSuccess: entriesIsReady } = useGetEntriesQuery()
  const { isSuccess: productsIsReady } = useGetProductsQuery()
  const optionsIsReady = useGetOptions()
  const dataIsReady = entriesIsReady && profileIsReady && optionsIsReady && productsIsReady

  const cards = useGetMyProducts()
  const hasLinkedCards = Boolean(cards.length)

  useEffect(() => {
    if (!dataIsReady || hasLinkedCards) return

    router.replace('/login/auth-error?reason=has_no_active_cards')
  }, [dataIsReady, hasLinkedCards, router])

  useEffect(() => {
    if (cards.length !== 1 || Boolean(defaultCardMnemocode) || !entriesIsReady) return

    dispatch(setDefaultCard({ cardMnemocode: cards[0].entryMnemocode }))
  }, [cards, cards.length, defaultCardMnemocode, dispatch, entriesIsReady])

  if (dataIsReady && hasLinkedCards) return children

  return fallback
}
