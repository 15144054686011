import qs from 'qs'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  ClientOption,
  Option,
  ProductOptions,
  ByProduct,
  ByProgram,
  ProductOptionsByProgram,
  ProductOptionsByProduct,
} from '~/models/options'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { createSelector } from 'reselect'
import { cancelledEntriesSelector, activeEntriesSelector } from '~/store/api/entries'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { RootState } from '~/store/redux-store'
import { processActions } from '~/store/slices/process'
import { gatewayApiQuery, getGatewayApiUrlFromQueryApi, shouldUseGatewayApi } from '~/lib/gatewayApi'
import { getFetchConfigParamsData } from './utils/getFetchConfigParamsData'

const axiosRTQuery = createAxiosRTQuery()

export const optionsApi = createApi({
  reducerPath: 'optionsQuery',
  baseQuery: axiosRTQuery({
    baseUrl: '',
  }),
  tagTypes: ['ClientOptions'],
  endpoints: (build) => ({
    getAllOptions: build.query<Option[], void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, bankMnemocode, company } = getFetchConfigParamsData(queryApi)

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `00000001/v2/microservice/metadata/options/list`,
              method: 'GET',
              params: {
                profile_code: profile_mnemocode,
                bankMnemocode,
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/options/all`,
              method: 'GET',
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as Option[]
        const data: Option[] = resData.filter((option) => option.bankMnemocode === String(bankMnemocode))
        // Этот патчинг нужен, если на бэке нет флага visible
        // const data = dataRaw.map((el) => ({ ...el, visible: true }))

        return { data }
      },
    }),
    getClientOptions: build.query<Record<string, ClientOption[]>, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        const { entryMnemocodes } = getOptionsParams(queryApi)

        const results = await Promise.all(
          entryMnemocodes.map(async (cardMnemocode) => {
            const res = !shouldUseGatewayApi(company)
              ? await fetchWithBaseQuery({
                  url: '00000001/v2/microservice/metadata/options/option/card',
                  params: {
                    cardMnemocode,
                    profile_code: profile_mnemocode,
                  },
                  method: 'GET',
                })
              : await gatewayApiQuery({
                  url: `${getGatewayApiUrlFromQueryApi(
                    queryApi
                  )}/client/v0/payment-cards/param-d-${cardMnemocode}/options`,
                  method: 'GET',
                })
            const data = res.data as ClientOption[]

            return { cardMnemocode, data }
          })
        )

        if (entryMnemocodes.length && (!results?.length || results?.some((el) => !el))) {
          return { error: 'Something in API went wrong' }
        }

        const data: Record<string, ClientOption[]> = {}
        for (const result of results) {
          data[result.cardMnemocode] = result.data
        }

        return { data }
      },
      providesTags: ['ClientOptions'],
    }),
    getProductOptions: build.query<ProductOptions, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        const { entryClasses } = getOptionsParams(queryApi)

        const results = await Promise.all(
          entryClasses.map(async (entryClass) => {
            const res = !shouldUseGatewayApi(company)
              ? await fetchWithBaseQuery({
                  url: '00000001/v2/microservice/metadata/options',
                  params: {
                    cardCode: entryClass,
                    profile_code: profile_mnemocode,
                  },
                  method: 'GET',
                })
              : await gatewayApiQuery({
                  url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/options`,
                  method: 'GET',
                  params: {
                    paymentCardClass: entryClass,
                  },
                })
            const options = res.data as ByProduct[]

            return { entryClass, options }
          })
        )

        if (entryClasses.length && (!results?.length || results?.some((el) => !el))) {
          return { error: 'Something in API went wrong' }
        }

        const byProduct: ProductOptionsByProduct = {}
        const byProgram: ProductOptionsByProgram = {}

        for (const { options, entryClass } of results) {
          byProduct[entryClass] = options

          for (const { programs, name, option_id } of options) {
            programs.forEach((program) => {
              const { loyalty_code } = program
              const optionProgram: ByProgram = { ...program }

              optionProgram.option_name = name
              optionProgram.productClass = entryClass
              optionProgram.optionId = option_id

              if (byProgram[loyalty_code]) {
                byProgram[loyalty_code] = [...byProgram[loyalty_code], optionProgram]
              } else {
                byProgram[loyalty_code] = [optionProgram]
              }
            })
          }
        }

        const data = {
          byProduct,
          byProgram,
        }

        return { data }
      },
    }),
    setProductOption: build.mutation<
      null,
      { optionMnemocode: string; loyaltyEntryClassName: string; pcMnemocodes: string[] }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)
        const { optionMnemocode, loyaltyEntryClassName, pcMnemocodes } = args
        const { dispatch } = queryApi

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `metadata/options/massive`,
              method: 'POST',
              params: {
                optionMnemocode,
                loyaltyEntryClassName,
                pcMnemocodes,
                profile_code: profile_mnemocode,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/options/massive`,
              method: 'POST',
              params: {
                optionMnemocode,
                loyaltyProgramClass: loyaltyEntryClassName,
                paymentCardMnemocodes: pcMnemocodes,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
              },
            })

        dispatch(processActions.resetProcess())

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        return { data: null }
      },
      invalidatesTags: ['ClientOptions'],
    }),
  }),
})

export const {
  useGetAllOptionsQuery,
  useGetClientOptionsQuery,
  useGetProductOptionsQuery,
  useSetProductOptionMutation,
} = optionsApi

export const allOptionsSelector = createSelector(
  optionsApi.endpoints.getAllOptions.select(),
  (result) => result?.data ?? []
)

export const clientOptionsSelector = createSelector(
  optionsApi.endpoints.getClientOptions.select(),
  (result) => result?.data ?? {}
)

const productOptionsSelector = createSelector(optionsApi.endpoints.getProductOptions.select(), (result) => result?.data)

export const optionsByProductSelector = createSelector(
  productOptionsSelector,
  (productOptions) => productOptions?.byProduct ?? {}
)

export const optionsByProgramSelector = createSelector(
  productOptionsSelector,
  (productOptions) => productOptions?.byProgram ?? {}
)

export const productProgramsAllProgramsSelector = createSelector(optionsByProgramSelector, (byProgram) =>
  Object.keys(byProgram)
)

export const productProgramsByProductSelector = createSelector(optionsByProductSelector, (optionsByProduct) => {
  const programsByProduct: Record<string, string[]> = {}

  Object.entries(optionsByProduct).forEach(([product, options]) => {
    const programsSet = new Set<string>()

    options.forEach(({ programs }) => {
      programs.forEach(({ loyalty_code }) => programsSet.add(loyalty_code))
    })

    programsByProduct[product] = [...programsSet]
  })

  return programsByProduct
})

function getOptionsParams(api: BaseQueryApi) {
  const excludedCards = [
    'UNCONERUBCBВDIG',
    'UNCONERUBSMARTCBDIG',
    'UNCONERUBAUTOVCBDIG',
    'UNCAIRVAL',
    'UNCPRIMEVAL',
    'UNCEXTRAVAL',
    'UNCAIRVAL',
    'UNCEXTRAVAL',
    'UNCONERUBCBDIG',
    'UNCPRIMEVAL',
    'UNCVIPVAL',
  ]

  const { getState } = api
  const state = getState() as unknown as RootState

  const entriesRaw = activeEntriesSelector(state)
  const cancelledRaw = cancelledEntriesSelector(state)
  const entries = entriesRaw.filter((entry) => entry.product_class === 'PC')
  const cancelled = Object.values(cancelledRaw).filter((entry) => entry.product_class === 'PC')
  const entriesWithCancelled = [...entries, ...cancelled]

  const entryMnemocodes = entries.map(({ mnemocode }) => mnemocode)
  const entryClasses = entriesWithCancelled
    .map(({ entry_class }) => entry_class)
    .filter((entryClass) => !excludedCards.includes(entryClass))

  return { entryMnemocodes, entryClasses }
}
