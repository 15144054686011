import React, { useCallback, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useMetrika } from '~/hooks/useMetrika'
import { useGetProgramStatusText } from '~/hooks/useGetProgramStatusText'
import { Break } from '@/Break'
import { useTypedSelector } from '~/store/redux-store'
import { AnimatedContainer } from '@/MultiStepModal/components/AnimatedContainer'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useIntl } from 'react-intl'
import { Expanded } from '@/Expanded'
import { Container, SignupNotice } from './common/stylesCommon'
import { Help } from './common/Help'
import { GoToRegistrationBtn } from './common/GoToRegistrationBtn'
import { ProgramNumberInput } from './common/ProgramNumberInput'
import { useAuthorization } from '../hooks/useAuthorization'
import { Meta, Row } from './common/Meta'
import { ButtonWithSuccessState } from './common/ButtonWithSuccessState'

interface Props {
  onNextClick?: () => void
}

export function Authorization({ onNextClick }: Props) {
  const intl = useIntl()
  const { reachGoal } = useMetrika()

  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const program = useGetPrograms(selectedProgram as string)

  const { hasEntry, entryName, details, entryStatus } = program
  const { icon, name, inputMask, authorizeType, canRegister, caption, signupNotice, signupResultButtonText } = details

  const { cardNumber, handleInput, checkNumberValidity, doAuthorization, resetStatus, authorizationProcessStatus } =
    useAuthorization(selectedProgram!)

  useEffect(() => {
    if (onNextClick && hasEntry) onNextClick()
  }, [hasEntry, onNextClick])

  const cardNr = entryName ?? (intl.messages[`app.program.no_entry_name`] as unknown as string)
  const cardStatusText = useGetProgramStatusText(selectedProgram!)

  const metaRows: Row[] = [{ param: intl.messages[`app.program.card_name_prefix`] as unknown as string, value: cardNr }]
  if (cardStatusText) {
    metaRows.push({ param: intl.messages[`app.program.status_prefix`] as unknown as string, value: cardStatusText })
  }

  const authTypeIsOnline = authorizeType === 'online'
  const authTypeIsDelayed = authorizeType === 'delayed_online' || authorizeType === 'delayed_charges'
  const isPreviousAuthFailed = entryStatus === 'E'
  const isSuccess = authorizationProcessStatus === 'isSuccess'
  const hasNoCurrentProcessStatus = authorizationProcessStatus === 'void'
  const isServerError = authorizationProcessStatus === 'isError'
  const isReplaceMode = hasEntry
  const isNumberChanged = cardNumber !== entryName
  const isNumberValid = checkNumberValidity(cardNumber)

  const showCheckEnteredNumberHint = isNumberChanged && isNumberValid && hasNoCurrentProcessStatus
  const authOnlineIsOk = authTypeIsOnline && isSuccess && !isPreviousAuthFailed
  const authDelayedIsOk = authTypeIsDelayed && isSuccess && !isPreviousAuthFailed

  const showNotice = Boolean(signupNotice) && !canRegister && !hasEntry
  const showRegistrationBtn = !hasEntry && canRegister

  const isButtonClickable = isNumberValid && !isSuccess && isNumberChanged

  let buttonText: string
  switch (true) {
    case authOnlineIsOk || authDelayedIsOk:
      buttonText = signupResultButtonText
      break
    case isReplaceMode:
      buttonText = intl.messages['app.program.authorization.change_number'] as unknown as string
      break
    default:
      buttonText = intl.messages['app.program.authorization.authorize_program'] as unknown as string
  }

  const authorizationHandler = useCallback(() => {
    if (!isButtonClickable) return
    reachGoal({ target: 'doAuthorization', params: { doAuthorization_Program: name } })
    doAuthorization(cardNumber)
  }, [cardNumber, doAuthorization, isButtonClickable, name, reachGoal])

  const buttonClickHandler = authDelayedIsOk ? resetStatus : authorizationHandler

  return (
    <AnimatedContainer animationKey="Authorization">
      <Meta icon={icon} title={name} rows={metaRows} />
      <Container>
        <ProgramNumberInput
          label={caption}
          mask={inputMask}
          value={cardNumber}
          handleChange={handleInput}
          disabled={isSuccess}
          isError={isPreviousAuthFailed}
        />
        <AnimatePresence>
          {showCheckEnteredNumberHint && <Help type="hint" program={program} />}
          {authOnlineIsOk && <Help type="resultIsOK" program={program} />}
          {authDelayedIsOk && <Help type="resultIsDelayed" program={program} />}
          {isPreviousAuthFailed && (
            <>
              <Help type="isPreviousAuthFailed" program={program} />
              <Help type="willPaySoonInfo" program={program} />
            </>
          )}
          {isServerError && <Help type="isServerError" program={program} />}
        </AnimatePresence>
        <Expanded />
        <ButtonWithSuccessState
          isDisabled={!isButtonClickable}
          onClick={buttonClickHandler}
          variant={isReplaceMode ? 'outlined' : 'contained'}
          isSuccess={authOnlineIsOk}
        >
          {buttonText}
        </ButtonWithSuccessState>
        {showNotice && (
          <>
            <Break indent={16} />
            <SignupNotice dangerouslySetInnerHTML={{ __html: signupNotice }} />
          </>
        )}
      </Container>
      {showRegistrationBtn && <GoToRegistrationBtn programName={program.details.name} />}
    </AnimatedContainer>
  )
}
