import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { PrcMode } from '~/models'
import { getFetchConfigParamsData } from './getFetchConfigParamsData'

interface FetchingParamsProps {
  api: BaseQueryApi
  currency?: string
  period?: string
  card_mnemocode?: string
  entry_group_key?: string
  paramIsProfileId?: boolean
  paramIsProfileCode?: boolean
  prc?: 'prcMode' | 'prc_mode'
  cache?: boolean
}

interface Params extends Omit<FetchingParamsProps, 'api' | 'prc'> {
  profile_code?: string
  profileId?: string
  prcMode?: PrcMode
  prc_mode?: PrcMode
}

export function getFetchingParams({
  api,
  currency,
  period,
  card_mnemocode,
  entry_group_key,
  paramIsProfileId,
  paramIsProfileCode,
  prc,
  cache,
}: FetchingParamsProps) {
  const params: Params = {}
  const { profile_mnemocode, prc_mode } = getFetchConfigParamsData(api)

  if (period) {
    params.period = period
  }
  if (currency) {
    params.currency = currency
  }
  if (card_mnemocode && !entry_group_key) {
    params.card_mnemocode = card_mnemocode
  }
  if(entry_group_key) {
    params.entry_group_key = entry_group_key
  }
  if (paramIsProfileCode) {
    params.profile_code = profile_mnemocode
  }
  if (paramIsProfileId) {
    params.profileId = profile_mnemocode
  }
  if (prc) {
    if (prc === 'prcMode') params.prcMode = prc_mode
    if (prc === 'prc_mode') params.prc_mode = prc_mode
  }
  if (typeof cache !== 'undefined') {
    params.cache = cache
  }

  return params
}
